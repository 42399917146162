import { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import { folderName } from '../Constants';
import { Modal, Button, Form } from 'react-bootstrap';
import * as turf from '@turf/turf';

import '../styles/MarkupTool.css';

AWS.config.update({
    accessKeyId : process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey : process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region : 'us-east-1'
});
var s3 = new AWS.S3({apiVersion: '2006-03-01'});

let clickDelay = false;

function MarkupTool({ map, toggleMarkupTool, toolActive }) {
    const [ savedLabels, setSavedLabels ] = useState(false);

    const [ stage, setStage] = useState(false);
    const [ arrowCount, setArrowCount ] = useState(0);

    const [ modalOpen, toggleModal ] = useState(false);
    const [ showDLButton, setShowDLButton ] = useState(false);

    const [ text, setText ] = useState('');
    const [ isChecked, setChecked ] = useState(false);
    const [ newFeatureIdx, setNewFeatureIdx ] = useState('');

    // Get labels && add Layers
    useEffect(() => {
        if (!savedLabels && map) {
            var downloadParams = { Bucket: "integrated-gdal-saved-files", Key: `${folderName}/saved-file.json` };
            var downloadPromise = s3.getObject(downloadParams).promise();
            downloadPromise.then( function(data) {
                let objectData = JSON.parse(data.Body.toString('utf-8'));
                setSavedLabels(objectData);
                addLayers(objectData);

                console.log('SAVED LABELS: ', objectData);
            })
            .catch(error => {
                console.log('NO SAVED LABELS FILE FOUND');

                setSavedLabels({ labels : [] });
                addLayers(false);
            });
        }
    }, [map]);

    // Refresh the event listener with every change to the dependencies so that it always has fresh values... 
    useEffect(() => {
        if (map && toolActive) {
            map.getCanvas().style.cursor = 'crosshair';

            map.on('click', mouseClick);
            map.on('touchmove', mobileDrag);
            map.once('touchend', onUp);

            if (stage === 'drawing-arrow') {
                map.on('mousemove', updateArrow);
            }

            return () => {
              map.off('click', mouseClick)
              map.off('touchmove', mobileDrag);
              map.off('touchend', onUp);
              if (stage === 'drawing-arrow') {
                map.off('mousemove', updateArrow);
              }
            }
        }
    }, [ stage, text, newFeatureIdx, arrowCount, toolActive ])

    useEffect(() => {
        if (toolActive) {
            setStage('start');
        }
    }, [toolActive]);


    const addLayers = (data) => {
        let allTextFeatures = [];
        let allArrowFeatures = [];

        if (data) {
            data.labels.forEach(label => {
                allTextFeatures.push(label.textFeature)
                allArrowFeatures.push(...label.arrowFeatures)
            })

            if (allArrowFeatures.length > 0) {
                setArrowCount(allArrowFeatures.length);
            }
        }

        //console.log(allTextFeatures);
        //console.log(allArrowFeatures);

        if (map && !map.getSource('annotations')) {
            map.addSource('annotations', {
                type : 'geojson',
                data : {
                  "type": "FeatureCollection",
                  "features": allTextFeatures
                }
            });

            if (!map.getLayer('annotations-layer')) {
                map.addLayer({
                    "id": 'annotations-layer',
                    "type": "symbol",
                    "source": 'annotations',
                    "layout": {
                        'text-size': 16,
                        'text-field': "{custom_text}",
                    },
                    "paint" : {
                        'text-color' : '#fff'
                    }
                });
            }
        }

        if (map && !map.getSource('arrows')) {
            map.addSource('arrows', {
                type : 'geojson',
                data : {
                  "type": "FeatureCollection",
                  "features": allArrowFeatures
                }
            });

            if (!map.getLayer('arrows-layer')) {
                map.addLayer({
                    "id": 'arrows-layer',
                    "type": "line",
                    "source": 'arrows',
                    "paint" : {
                        "line-color" : '#fff',
                        "line-width" : 2
                    },
                    "layout" : {
                        'line-cap': 'round',
                        'line-join': 'round'
                    }
                });
            }
        }
    }

    const mouseClick = (e) => {
        if (!clickDelay) {
            if (stage === 'start') {
                initializeArrow(e);
            } else if (stage === 'drawing-arrow') {
                setStage(false);
                toggleModal(true);
            } else if (stage === 'placing-text') {
                placeText(e);
            }
            clickDelay = true;
            setTimeout(() => {
                clickDelay = false;
            }, 500);
        }
    }

    const mobileDrag = (e) => {
        e.preventDefault();
        map.dragPan.disable();

        if (stage === 'start') {
            initializeArrow(e);
        } else if (stage === 'drawing-arrow') {
            updateArrow(e);
        }
    }

    const onUp = (e) => {
        map.dragPan.enable();
        if (stage === 'drawing-arrow') {
            setStage(false);
            toggleModal(true);
        } else if (stage === 'placing-text') {
            placeText(e);
        }
    }

    const initializeArrow = (e) => {
        let arrowsSource = map.getSource('arrows');
        let data = arrowsSource._data;

        const arrowStem = {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "LineString",
                "coordinates": [
                   [ e.lngLat.lng,  e.lngLat.lat ],
                   [ e.lngLat.lng,  e.lngLat.lat ]
                ]
            }
        }
        const arrowHeadTop = getArrowHead(arrowStem, 'top');
        const arrowHeadBottom= getArrowHead(arrowStem, 'bottom');

        data.features.push(arrowStem, arrowHeadTop, arrowHeadBottom);
        arrowsSource.setData(data);

        setStage('drawing-arrow');
    }

    const getArrowHead = (line, type) => {
        const arrowHeadFeature = {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "LineString",
                "coordinates": [
                   line.geometry.coordinates[0],
                   []
                ]
            }
        }

        const distance = turf.lineDistance(line);

        const startPoint = turf.point(line.geometry.coordinates[0]);
        const endPoint = turf.point(line.geometry.coordinates[1]);
        const bearing = turf.bearing(startPoint, endPoint)

        const pointBearing = type === 'top' ? bearing - 45 : bearing + 45;
        const newLine = turf.destination(startPoint, distance / 5, pointBearing); 
        arrowHeadFeature.geometry.coordinates[1] = newLine.geometry.coordinates;

        return arrowHeadFeature;
    }

    const updateArrow = (e) => {
        let arrowsSource = map.getSource('arrows');
        let data = arrowsSource._data;

        if (data.features[arrowCount]) {
            // Set arrow stem
            data.features[arrowCount].geometry.coordinates[1] = [ e.lngLat.lng, e.lngLat.lat ];

            if (data.features[arrowCount + 1]) {
                data.features[arrowCount + 1] = getArrowHead(data.features[arrowCount], 'top');
            } 

            if (data.features[arrowCount + 2]) {
                data.features[arrowCount + 2] = getArrowHead(data.features[arrowCount], 'bottom');
            }

            arrowsSource.setData(data);
        }
    }

    const placeText = (e) => {
        let annotationSource = map.getSource('annotations');
        let data = annotationSource._data;

        if (!newFeatureIdx) {
            data.features.push({
                "type": "Feature",
                "properties": {
                    "custom_text" : text
                },
                "geometry": {
                    "type": "Point",
                    "coordinates": [
                        e.lngLat.lng,
                        e.lngLat.lat,
                ]}
            })
            setNewFeatureIdx(data.features.length);
            setArrowCount(arrowCount + 3);
        } else {
            data.features[newFeatureIdx - 1].geometry.coordinates = [ e.lngLat.lng, e.lngLat.lat ];
        }

        annotationSource.setData(data);
    }

    const donePlacing = () => {
        if (isChecked) { 
            saveLabel() 
            setChecked(false);
        }

        setNewFeatureIdx(false);
        setText('');
        setStage(false);
    }

    const saveLabel = () => {
        let arrowsSource = map.getSource('arrows');
        let arrowData = arrowsSource._data;

        let annotationSource = map.getSource('annotations');
        let textData = annotationSource._data;

        const newLabel = {
            textFeature : textData.features[newFeatureIdx - 1],
            arrowFeatures : [
                arrowData.features[arrowCount - 3],
                arrowData.features[arrowCount - 2],
                arrowData.features[arrowCount - 1]
            ]
        }

        if (newLabel.textFeature && newLabel.arrowFeatures.every(feature => typeof feature !== "undefined" && feature !== null)) {
            savedLabels.labels.push(newLabel);
            setSavedLabels(savedLabels);
        } else {
            console.log("ERROR SAVING FEATURE");
        }

        //console.log(newLabel);

        console.log(savedLabels);

        const Body = JSON.stringify(savedLabels);
        //const Body = JSON.stringify({ labels : [] });

        var uploadParams = { 
            Bucket: "integrated-gdal-saved-files", 
            Key: `${folderName}/saved-file.json`, 
            Body 
        };

        var uploadPromise = s3.putObject(uploadParams).promise();
        uploadPromise.then( function(data) {
            console.log("Successfully uploaded data");
        });
    }

    const cancel = (removeFeatures = false) => {
        // Wipe map [ may want to remove the entire source + layer, for now just clearing it ]
        /*
        ['annotations', 'arrows'].forEach(sourceName => {
            let source = map.getSource(sourceName); 
            let data = source._data;
            data.features = [] 

            source.setData(data);
        });
        */

        if (removeFeatures) {
            let source = map.getSource('arrows'); 
            let data = source._data;

            data.features.splice(data.features.length - 3, 3);
            source.setData(data);

            if (newFeatureIdx) {
                let textSource = map.getSource('annotations'); 
                let textData = textSource._data;

                textData.features.splice(textData.features.length - 1, 1);
                textSource.setData(textData);

                setArrowCount(arrowCount - 3);
            }
        }

        map.getCanvas().style.cursor = 'unset';
        toggleMarkupTool(false);

        setStage(false);
        clear();
    }

    const clear = () => {
        setNewFeatureIdx(false);
        setText('');
        setChecked(false);
        setShowDLButton(false);
    }

    const reset = () => {
        clear();
        setStage('start');
    }

    const downloadCanvas = () => {
        const canvas = document.getElementsByClassName("mapboxgl-canvas")[0];

        canvas.toBlob(function(blob) {
            var newImg = document.createElement('img'), url = URL.createObjectURL(blob);

            newImg.onload = function() {
                // no longer need to read the blob so it's revoked
                URL.revokeObjectURL(url);
            };

            var a = document.createElement("a");
            a.href = url;
            a.setAttribute("download", 'IG Screenshot');
            a.click();
        });
    }

    return (
        <div> 
            <div className="markup-tool-button-wrap">
                { stage === 'placing-text' ? 
                    <div>
                        <Button disabled={!newFeatureIdx ? true : false} onClick={() => {donePlacing(); setShowDLButton(true)} }> Done Placing Text </Button>
                        <Button variant="secondary" onClick={() => cancel(true)}> Cancel </Button>
                    </div>
                : false }

                { showDLButton ? 
                    <div>
                        <Button onClick={() => downloadCanvas()}> Download Image </Button>
                        <Button onClick={() => reset()}> Place Another </Button>
                        <Button variant="secondary" onClick={() => cancel()}> Done </Button>
                    </div>
                : false }
            </div>

            <Modal size="md" show={modalOpen} onHide={() => toggleModal(false)} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Add Text to Map</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Text</Form.Label>
                            <Form.Control onChange={(e) => setText(e.target.value)} />
                        </Form.Group>

                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Save this label" onChange={() => setChecked(!isChecked)}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button disabled={text.length === 0 ? true : false} onClick={() => {toggleModal(); setStage('placing-text')} }>Place on Map</Button>
                    <Button variant="secondary" onClick={() => {toggleModal(); cancel(true)} }>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default MarkupTool;
