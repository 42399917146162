import { SET_BOUNDS, SET_BOUNDS_DATA } from '../actions/actions_map';

const map = (state = {
  bbox : false,
  bboxData : false
}, action) => {
  switch (action.type) {
    case SET_BOUNDS:
      return {
        ...state,
        bbox : action.payload
      };
    case SET_BOUNDS_DATA:
      return {
        ...state,
        bboxData : action.payload
      };
    default:
      return state
  }
}

export default map
