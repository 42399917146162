import { awsRootURL, folderName } from '../Constants';

import { SET_INITIAL_DATA } from '../actions/actions_initial_data';

const skins = (state = {
  allSkins : []
}, action) => {
  switch (action.type) {
    case SET_INITIAL_DATA:
      var allSkins = [];
      if(action.payload.skins) {
        action.payload.skins.forEach(skin => {
          var thisSkin = JSON.parse(JSON.stringify(skin));
          if(thisSkin.file.indexOf('http') === -1) {
            thisSkin.file = awsRootURL + folderName + '/skins/' + thisSkin.file;
          }
          allSkins.push(thisSkin)
        })
      }
      return {
        ...state,
        allSkins : allSkins
      };
    default:
      return state
  }
}

export default skins
