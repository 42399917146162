import { awsRootURL, folderName } from '../Constants';

export const SET_INITIAL_DATA = 'SET_INITIAL_DATA';

export function getInitialData() {
  return (dispatch) => {
    var filesJsonFile = awsRootURL + folderName + '/files.json?v=' + (Math.random() * 100);
    return fetch(filesJsonFile).then(resp => resp.json()).then(response => {
      console.log(response)
      dispatch(setInitialData(response));
     })
    .catch(error => console.log(error));
  }
}

export function setInitialData(data) {
  return {
    type : SET_INITIAL_DATA,
    payload : data
  }
}
