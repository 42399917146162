import { awsRootURL, folderName } from '../Constants';

import { SET_INITIAL_DATA } from '../actions/actions_initial_data';

const pointclouds = (state = {
  allPointclouds : []
}, action) => {
  switch (action.type) {
    case SET_INITIAL_DATA:
      if(action.payload.pointclouds) {
        var allPointclouds = action.payload.pointclouds;
        return {
          ...state,
          allPointclouds : allPointclouds
        };
      }
    default:
      return state
  }
}

export default pointclouds
