import { SET_INITIAL_DATA } from '../actions/actions_initial_data';

const projection = (state = {
  projectionString : false
}, action) => {
  switch (action.type) {
    case SET_INITIAL_DATA:
      var projectionString = action.payload.projection;
      return {
        ...state,
        projectionString : projectionString
      };
    default:
      return state
  }
}

export default projection
