import { SET_INITIAL_DATA } from '../actions/actions_initial_data';

const tilesets = (state = {
  allTilesets : []
}, action) => {
  switch (action.type) {
    case SET_INITIAL_DATA:
      var allTilesets = action.payload.tilesets;
      return {
        ...state,
        allTilesets : allTilesets
      };
    default:
      return state
  }
}

export default tilesets
