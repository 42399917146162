import { awsRootURL, folderName } from '../Constants';

import { SET_INITIAL_DATA } from '../actions/actions_initial_data';

const files = (state = {
  allFiles : []
}, action) => {
  switch (action.type) {
    case SET_INITIAL_DATA:
      var allFiles = [];
      if(action.payload.files) {
        var newFiles = JSON.parse(JSON.stringify(action.payload.files));
        newFiles.forEach(download => {
          checkIfFolder(download);
        })
      }
      return {
        ...state,
        allFiles : newFiles
      };
    default:
      return state
  }
}

const checkIfFolder = (arrayItem) => {
  if(arrayItem.folderName) {
    arrayItem.files.forEach(download => {
      checkIfFolder(download);
    })
  } else {
    if(arrayItem.file.indexOf('http') === -1) {
      arrayItem.file = awsRootURL + folderName + '/files/' + arrayItem.file;
    }
  }
}

export default files
