import { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, ListGroup, Button } from 'react-bootstrap'
import { connect } from "react-redux";

import { getInitialData } from '../actions/actions_initial_data';
import { getBoundsFromEntry, getBoundsFromLatLngEntry, setBoundsData } from '../actions/actions_map';
import { folderName } from '../Constants';

import FlatMap from './FlatMap';
import Terrain from './Terrain';
import PointCloud from './PointCloud';
import Files from './Files';

function App({ dispatch, skins, tilesets, pointclouds }) {
  const [ currentMap, setCurrentMap ] = useState('2D');
  const [ clickedCollapse, setClickedCollapse ] = useState(false)
  const [ modalShow, changeModalShow ] = useState(false);
  const [ currentMeasurements, setCurrentMeasurements ] = useState( [] );
  const [ lng, setLng ] = useState('');
  const [ lat, setLat ] = useState('');
  const [ mer, setMer ] = useState('4');
  const [ rge, setRge ] = useState('1');
  const [ twp, setTwp ] = useState('1');
  const [ sec, setSec ] = useState('');
  const [ qtr, setQtr ] = useState('');

  // If there are tilesets, then fetch the bounds for mapbox
  // If no tilesets, but geoJSONs, use them to create a containing bounds
  // If neither, then just load map over Alberta

  // If no skins, terrain viewer does not appear

  // If no pointclouds, 3D viewer does not appear

  useEffect(() => {
    if(folderName) {
      dispatch(getInitialData());
    }
  }, []);

  const setBounds = (mer,rge,twp,sec,qtr) => {
    if(mer!=='' && rge!=='' && twp!=='') {
      // Do API call here and set map bounds
      dispatch(getBoundsFromEntry(mer,rge,twp,sec,qtr))
      dispatch(setBoundsData({mer,rge,twp,sec,qtr}))
    }
  }

  const setBoundsLatLng = (lat, lng) => {
    if(lat!=='' && lng!=='') {
      // Do API call here and set map bounds
      dispatch(getBoundsFromLatLngEntry(lat, lng))
    }
  }

  useEffect(() => {
    var newCurrentMap = '2D';
    if(tilesets.length === 0) {
      newCurrentMap = 'Terrain'
      if(skins.length === 0) {
        newCurrentMap = '3D';
      }
    }
    setCurrentMap(newCurrentMap);
  }, [skins, tilesets, pointclouds]);

  return (
    <div>
      <Navbar bg="light" variant="light" expand="lg">
        <Navbar.Brand href="#home">
          <img
            src="/images/Integrated Geomatics logo-Crop.jpg"
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="Integrated Geomatics"
          />
        </Navbar.Brand>
        <Navbar.Brand href="#home">Digital Twin Asset <span className="d-xs d-md-none"><br /></span>Mapping & Management System</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse onClick={() => setClickedCollapse(!clickedCollapse)} id="basic-navbar-nav" className="justify-content-end">
          <Navbar.Text>
            <div className="location-entry">
              <span>Latitude</span>
              <input onChange={(e) => setLat(e.target.value)} value={lat} />
            </div>
            <div className="location-entry">
              <span>Longitude</span>
              <input onChange={(e) => setLng(e.target.value)} value={lng} />
            </div>
            <div className="location-entry" style={{marginRight:0, width:'40px'}}>
              <Button variant="secondary" size="sm" className="little-button" onClick={() => setBoundsLatLng(lat, lng)}>Go</Button>
            </div>
            <hr className="vertical-divider" />
            <div className="location-entry">
              <span>Meridian</span>
              <select onChange={(e) => setMer(e.target.value)} value={mer}>
                <option> </option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </select>
            </div>
            <div className="location-entry">
              <span>Range</span>
              <input type="number" min="1" max="24" onChange={(e) => setRge(e.target.value)} value={rge} />
            </div>
            <div className="location-entry">
              <span>Township</span>
              <input type="number" min="1" max="130" onChange={(e) => setTwp(e.target.value)} value={twp} />
            </div>
            <div className="location-entry">
              <span>Section</span>
              <input type="number" min="1" max="36" onChange={(e) => setSec(e.target.value)} value={sec} />
            </div>
            <div className="location-entry">
              <span>Quarter</span>
              <select onChange={(e) => setQtr(e.target.value)} value={qtr}>
                <option> </option>
                <option value="NW">NW</option>
                <option value="NE">NE</option>
                <option value="SW">SW</option>
                <option value="SE">SE</option>
              </select>
            </div>
            <div className="location-entry" style={{marginRight:0, width:'40px'}}>
              <Button variant="secondary" size="sm" className="little-button" onClick={() => setBounds(mer, rge, twp, sec, qtr)}>Go</Button>
            </div>
          </Navbar.Text>
          <Nav>
            <Nav.Link onClick={() => changeModalShow(!modalShow)}>Files</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className="selector-panel">
        <ListGroup horizontal>
          {tilesets && tilesets.length > 0 ?
            <ListGroup.Item className={currentMap==='2D' ? 'active' : ''} onClick={() => setCurrentMap('2D')}>2D</ListGroup.Item>
          : false }
          {pointclouds && pointclouds.length > 0 ?
            <ListGroup.Item className={currentMap==='3D' ? 'active' : ''}  onClick={() => setCurrentMap('3D')}>3D</ListGroup.Item>
          : false}
          {skins && skins.length > 0 ?
            <ListGroup.Item className={currentMap==='Terrain' ? 'active' : ''}  onClick={() => setCurrentMap('Terrain')}>Terrain</ListGroup.Item>
          : false}
        </ListGroup>
      </div>
      {!folderName ? <div className="provider-warning">You must enter a provider in the URL to view the app properly.</div> : false}
      {currentMap==='2D' ? <FlatMap clickedCollapse={clickedCollapse} setCurrentMap={setCurrentMap} /> : false }
      {currentMap==='3D' ? <PointCloud /> : false }
      {currentMap==='Terrain' ? <Terrain /> : false }
      <Files modalShow={modalShow} changeModalShow={changeModalShow} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  skins: state.skins.allSkins,
  tilesets : state.tilesets.allTilesets,
  pointclouds: state.pointclouds.allPointclouds,
});

export default connect(mapStateToProps)(App);
