import { useState, useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { Modal, Button, DropdownButton, Dropdown, Form } from 'react-bootstrap';
import ReactApexChart from 'react-apexcharts';
import * as turf from '@turf/turf';
import proj4 from 'proj4';
import CompassControl from 'mapbox-gl-controls/lib/compass';

import { connect } from "react-redux";

import { awsRootURL, folderName, drawStyles } from '../Constants';

import { setBounds } from '../actions/actions_map';

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

import MarkupTool from './MarkupTool';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

function FlatMap({ clickedCollapse, elevationTileset, geojsons, tilesets, projection, pointclouds, setCurrentMap, bbox, dispatch, bboxData }) {

  const [ map, setMap ] = useState(false);
  const measuredLines = useRef();
  const measuredLinesElevations = useRef();
  const measuredLinesTextGeoJSON = useRef();
  const currentDrawingLine = useRef();
  const [ featuresToMeasure, setFeaturesToMeasure ] = useState([]);
  const [ elevationsToDisplay, setElevationsToDisplay ] = useState([]);
  const [ geoJSONVisibility, setGeoJSONVisibility ] = useState([]);
  const [ tilesetVisibility, setTilesetVisibility ] = useState([]);
  const [ openElevation, setOpenElevation ] = useState(false);
  const [ barChartSeries, setBarChartSeries ] = useState(false);
  const [ randomRender, setRandomRender ] = useState(1);
  const [ polyOrLinePanel, setPolyOrLinePanel ] = useState(false)
  const [ currentLngLat, setCurrentLngLat ] = useState(false);

  const [ markupToolActive, toggleMarkupTool ] = useState(false);

  const measuredPolysRef = useRef();
  const [ measuredPolys, setMeasuredPolys ] = useState([])

  let showFeaturePopupsRef = useRef();
  const [ showFeaturePopups, _setShowFeaturePopups ] = useState(false);

  const setShowFeaturePopups = (val) => {
    showFeaturePopupsRef.current = val;
    _setShowFeaturePopups(val);
  }

  const reproject = (coords) => {
    return proj4("EPSG:3857", '+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees', coords);
  }

  const findProjectionString = (lng) => {
    if(lng > -114 && lng <= -108) {
      return '+proj=utm +zone=12 +ellps=GRS80 +datum=NAD83 +units=m +no_defs'
    }
    if(lng > -120 && lng <= -114) {
      return '+proj=utm +zone=11 +ellps=GRS80 +datum=NAD83 +units=m +no_defs'
    }
    if(lng > -126 && lng <= -120) {
      return '+proj=utm +zone=10 +ellps=GRS80 +datum=NAD83 +units=m +no_defs'
    }
    return '+proj=utm +zone=11 +ellps=GRS80 +datum=NAD83 +units=m +no_defs'
  }

  useEffect(() => {
    mapboxgl.accessToken = 'pk.eyJ1IjoibWFyaGxlbiIsImEiOiJjODQ3ZWRkMDdlOGQ3MmVhZGZkYTBjYTMwNzkwNzNjMyJ9.01tMkp2ZYFM7MLRshJRIJQ';
    var map = new mapboxgl.Map({
      container: 'map', // container id
      style: 'mapbox://styles/mapbox/satellite-v9', // style URL
      center: [-74.5, 40], // starting position [lng, lat]
      zoom: 9, // starting zoom
      preserveDrawingBuffer : true
    });

    var Draw = new MapboxDraw({
      controls : {
        point : false,
        combine_features : false,
        uncombine_features : false
      },
      styles : drawStyles
    })
    map.addControl(new CompassControl(), 'top-left');
    map.addControl(Draw, 'top-left');

    map.on('mousemove', (e) => {
      setCurrentLngLat(e.lngLat);
      if(Array.isArray(currentDrawingLine.current)) {
        var fakeLine = JSON.parse(JSON.stringify(currentDrawingLine.current));
        fakeLine.push([e.lngLat.lng, e.lngLat.lat]);
        var lineGeoJSON = { type : "FeatureCollection", features: [{
          type : "Feature",
          properties : {},
          geometry : {
            type : "LineString",
            coordinates : fakeLine
          }
        }]}
        var length = turf.length(lineGeoJSON);
        var midPoint = turf.along(lineGeoJSON.features[0], length/2);
        midPoint.properties.totalDistance = (length * 1000).toFixed(2) + 'm';

        var geoJSONtoAdd = false;
        if(measuredLinesTextGeoJSON.current) {
          geoJSONtoAdd = JSON.parse(JSON.stringify(measuredLinesTextGeoJSON.current));
          geoJSONtoAdd.features.push(midPoint)
        } else {
          geoJSONtoAdd = { type : "FeatureCollection", features: [midPoint]};
        }

        if(map.getSource('distance-text')) {
          map.getSource('distance-text').setData(geoJSONtoAdd)
        } else {
          map.addSource('distance-text', {
            type : 'geojson',
            data : { type : "FeatureCollection", features: [midPoint]}
          })
          map.addLayer({
            id : 'distance-text',
            source : 'distance-text',
            type : 'symbol',
            layout : {
              'text-field' : ['get', 'totalDistance'],
              'text-size' : 15,
              'text-allow-overlap' : true
            },
            paint : {
              'text-halo-color' : '#FFF',
              'text-halo-blur' : 0.5,
              'text-halo-width' : 3
            }
          })
        }
      }
    });
    map.on('touchstart', (e) => {
      setCurrentLngLat(e.lngLat);
    });

    // Add geolocate control to the map.
    map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      }),
      'top-left'
    );

    // Removing terrain to increase accuracy of measurements at high zoom
    map.on('zoomend', () => {
      var currentZoom = map.getZoom();
      if(currentZoom >= 19) {
        map.setTerrain(null)
      } else {
        map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });
      }
    })

    map.on('load', () => {

      // Adding layer purely for layering purposes
      map.addSource('geojson-layerer', {
        type : 'geojson',
        data : { type: "FeatureCollection", features: [] }
      })
      map.addLayer({ id : 'geojson-layerer', source: 'geojson-layerer', type: 'circle' }, 'gl-draw-line.cold')

      map.addSource('mapbox-dem', {
        'type': 'raster-dem',
        'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
        'tileSize': 512,
        'maxzoom': 14
      });
      // add the DEM source as a terrain layer with exaggerated height
      map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });

      // add a sky layer that will show when the map is highly pitched
      map.addLayer({
        'id': 'sky',
        'type': 'sky',
        'paint': {
          'sky-type': 'atmosphere',
          'sky-atmosphere-sun': [0.0, 0.0],
          'sky-atmosphere-sun-intensity': 15
        }
      });
      // Fetch tileset information like bounds
      setMap(map);
      loadTilesets(map, tilesets);
      loadGeoJSONs(map, geojsons);
      // loadPointClouds(map, pointclouds);
    })

    map.on('draw.modechange', ({ mode }) => {
      if(mode === 'draw_line_string') {
        currentDrawingLine.current = true;
      }
    })

    map.on('click', (e) => {
      if(currentDrawingLine.current) {
        if(typeof currentDrawingLine.current === 'boolean') {
          var newLineCoordinate = [ [e.lngLat.lng, e.lngLat.lat] ];
          currentDrawingLine.current = newLineCoordinate;
        } else if(Array.isArray(currentDrawingLine.current)) {
          var coordinateArray = JSON.parse(JSON.stringify(currentDrawingLine.current));
          coordinateArray.push([e.lngLat.lng, e.lngLat.lat]);
          currentDrawingLine.current = coordinateArray;
        }
      }
    })

    map.on('draw.create', ({ features }) => {
      console.log('created')
      if (features[0].geometry.type === "LineString") {
          // Add measurement
          if(!measuredLinesTextGeoJSON.current) {
            measuredLinesTextGeoJSON.current = { type : "FeatureCollection", features : [] }
          }
          if(Array.isArray(currentDrawingLine.current)) {
            console.log(currentDrawingLine.current)
            var fakeLine = JSON.parse(JSON.stringify(currentDrawingLine.current));
            currentDrawingLine.current = false;
            var lineGeoJSON = { type : "FeatureCollection", features: [{
              type : "Feature",
              properties : { },
              geometry : {
                type : "LineString",
                coordinates : fakeLine
              }
            }]}
            var length = turf.length(lineGeoJSON);
            var midPoint = turf.along(lineGeoJSON.features[0], length/2);
            midPoint.properties.id = features[0].id;
            midPoint.properties.totalDistance = (length * 1000).toFixed(2) + 'm';
            measuredLinesTextGeoJSON.current.features.push(midPoint);
            console.log('setting')
            map.getSource('distance-text').setData(measuredLinesTextGeoJSON.current)
          }
          // Add to panel
          var newLines = []
          var newElevations = []
          if(measuredLines.current) {
            newLines = JSON.parse(JSON.stringify(measuredLines.current))
            if(elevationTileset) {
              newElevations = JSON.parse(JSON.stringify(measuredLinesElevations.current))
            }
          }
          newLines.push(features[0]);
          measuredLines.current = newLines;
          //dispatch(setLines(newLines));
          setFeaturesToMeasure(newLines);

          var length = turf.length(features[0]);
          var currentQuery = 0;
          var elevationPoints = new Array(20);
          var eachPointLength = length/20;

          var testGeoJSON = { type : "FeatureCollection", features : [] }

          if(elevationTileset) {
            for(var i = 0; i <= 20; i++) { // into 20 even divisions
              setTimeout((index) => {
                var pointToQuery = turf.along(features[0], eachPointLength * index);

                fetch('https://api.mapbox.com/v4/'+ elevationTileset +'/tilequery/'+pointToQuery.geometry.coordinates[0]+','+pointToQuery.geometry.coordinates[1]+'.json?radius=20&limit=50&access_token=pk.eyJ1IjoibWFyaGxlbiIsImEiOiJjODQ3ZWRkMDdlOGQ3MmVhZGZkYTBjYTMwNzkwNzNjMyJ9.01tMkp2ZYFM7MLRshJRIJQ').then(resp => resp.json()).then(response => {
                  if(response.features[0]) {
                    elevationPoints[index] = response.features[0];
                    elevationPoints[index].properties.distance = eachPointLength * index;
                    testGeoJSON.features.push(response.features[0])
                  }
                  if(index === 20) {
                    newElevations.push(elevationPoints)
                    measuredLinesElevations.current = newElevations;
                    setElevationsToDisplay(newElevations)
                  }
                })
              }, 100 * i, i)
            }
          }
          setPolyOrLinePanel('lines');
      } else {
        let newPolys = [];
        if (measuredPolysRef.current) {
            newPolys = JSON.parse(JSON.stringify(measuredPolysRef.current));
        }
        newPolys.push(features[0]);
        measuredPolysRef.current = newPolys;
        setMeasuredPolys(newPolys);

        setPolyOrLinePanel('polys');
      }
    });

    map.on('draw.delete', ({ features }) => {
      var newCurrent = []

      if (features[0].geometry.type === 'LineString') {
          var newElevations = []
          measuredLines.current.forEach((feature, index) => {
            if(feature.id !== features[0].id) {
              newCurrent.push(feature);
              newElevations.push(measuredLinesElevations.current[index]);
            }
          })
          measuredLines.current = newCurrent;
          measuredLinesElevations.current = newElevations;
          setElevationsToDisplay(measuredLinesElevations.current)
          //dispatch(setLines(measuredLines.current));
          setFeaturesToMeasure(measuredLines.current);
          // Remove measurement
          var newMeasuredGeoJSON = { type : "FeatureCollection", features : [] }
          measuredLinesTextGeoJSON.current.features.forEach((feature, index) => {
            if(feature.properties.id !== features[0].id) {
              newMeasuredGeoJSON.features.push(feature);
            }
          });
          measuredLinesTextGeoJSON.current = newMeasuredGeoJSON;
          map.getSource('distance-text').setData(measuredLinesTextGeoJSON.current)
      } else {
          newCurrent = measuredPolysRef.current.filter(feature => feature.id !== features[0].id);

          measuredPolysRef.current = newCurrent;
          setMeasuredPolys(newCurrent);
      }

      if (newCurrent.length === 0) { setPolyOrLinePanel(false) }
    });

  }, []);

  useEffect(() => {
    if(map) {
      loadTilesets(map, tilesets);
      loadGeoJSONs(map, geojsons);
      // loadPointClouds(map, pointclouds);
    }
  }, [tilesets])

  useEffect(() => {
    if(openElevation !== false) {
      setTimeout(() => {
        setRandomRender(Math.random());
      }, 200);
    }
  }, [openElevation])

  useEffect(() => {
    if(map) {
      if(bbox) {
        map.fitBounds(turf.bbox(bbox), { duration : 0, padding: 20 })
        var polygonFromBbox = turf.bboxPolygon(turf.bbox(bbox));
        if(map.getSource('bbox-line')) {
          map.getSource('bbox-line').setData(polygonFromBbox)
        } else {
          map.addSource('bbox-line', {
            type : 'geojson',
            data : polygonFromBbox
          })
          map.addLayer({
            id : 'bbox-line',
            source : 'bbox-line',
            type : 'line',
            paint : {
              'line-color' : '#FFF',
              'line-width' : 1
            }
          })
        }

        var centroid = turf.centroid(bbox);
        // console.log(bboxData);

        if(!map.getSource('query-area-label')) {
          map.addSource('query-area-label', {
            type : 'geojson',
            data : centroid
          })
          map.addLayer({
            id : 'query-area-label',
            source : 'query-area-label',
            type : 'symbol',
            layout : {
              'text-size' : 15
            },
            paint : {
              'text-translate' : [0, -50],
              'text-color' : '#FFF',
              'text-halo-color' : '#000',
              'text-halo-width' : 1,
              'text-halo-blur' : 1
            }
          })
        } else {
          map.getSource('query-area-label').setData(centroid);
        }
        var label = '';
        console.log(bboxData)
        if(bboxData.sec) {
          label += bboxData.sec;
          if(bboxData.qtr) {
            label += bboxData.qtr;
          }
          label += '\n';
          label += `${bboxData.twp} - ${bboxData.rge} W${bboxData.mer}m`
        } else {
          label += `Twp ${bboxData.twp} - Rge ${bboxData.rge} W${bboxData.mer}m`
        }
        map.setLayoutProperty('query-area-label', 'text-field', label);
        map.setLayoutProperty('query-area-label', 'visibility', 'visible');

      } else if(bbox == null){
        alert('Query not valid. Please adjust and try again.');
        dispatch(setBounds(false));

        map.setLayoutProperty('query-area-label', 'visibility', 'none');
      }
    }
  }, [bbox])

  const loadPointClouds = (map, pointclouds) => {
    if(pointclouds.length > 0) {
      var popup = new mapboxgl.Popup({ closeOnClick: false, closeButton : false })
      pointclouds.forEach((pointcloud, index) => {
        if(!map.getSource(pointcloud.folder)) {
          var pointcloudPath = awsRootURL + folderName + "/pointclouds/" + pointcloud.folder + "/cloud.js?v=" + (Math.random() * 100);
          fetch(pointcloudPath).then(resp => resp.json()).then(response => {
            var lower = reproject([response.boundingBox.lx, response.boundingBox.ly]);
            var upper = reproject([response.boundingBox.ux, response.boundingBox.uy]);
            var bounds = [ lower[0], lower[1], upper[0], upper[1] ];
            var boundsPoly = turf.bboxPolygon(bounds)
            map.addSource(pointcloud.folder, {
              type : 'geojson',
              data : { type : "FeatureCollection", features : [boundsPoly] }
            });
            map.addLayer({
              id : pointcloud.folder,
              source : pointcloud.folder,
              type : 'fill',
              paint : {
                'fill-opacity' : 0.3,
                'fill-outline-color' : "#FFFFFF"
              }
            })
            map.on('touchstart', pointcloud.folder, (e) => {
              setCurrentMap('3D');
            });
            map.on('click', pointcloud.folder, (e) => {
              setCurrentMap('3D');
            });
            map.on('mousemove', pointcloud.folder, (e) => {
              popup.setLngLat([e.lngLat.lng, e.lngLat.lat]);
              popup.setHTML('Click to see the ' + pointcloud.name + ' pointcloud.');
              popup.addTo(map);
            })
            map.on('mouseout', pointcloud.folder, () => {
              popup.remove()
            });
          })
        }
      })
    }
  }

  const loadGeoJSONs = async (map, geojsons) => {
    if(geojsons.length > 0) {

      setGeoJSONVisibility(geojsons);
      // const visibleGeoJSONS = geojsons.filter(geojson => geojson.visible);

      //console.log(geojsons.map(g => { return g.name}) )
      var responses = await Promise.all(
        geojsons.map(async (geojson) => {
            var resp = await fetch(geojson.file + '?v=' + (Math.random() * 100))
            return await resp.json();
        })
      )
      //console.log(responses);

      for (var i = 0; i < responses.length; i++) {
          const response = responses[i];
          const geojson = geojsons[i];

          if (map.getSource(geojson.name)) {
            map.getSource(geojson.name).setData(response);
          } else {
            if(geojson.annotations){
              setAnnotations(map, response.features, geojson)
            }
            response.features.forEach(feature => {
              feature.properties = { ...feature.properties, name : geojson.name, propsToShow : geojson.propsToShow }
            })

            map.addSource(geojson.name, {
              type : 'geojson',
              data : response
            })
            var type = 'circle';
            var paint = {};
            if(response.features[0].geometry.type.indexOf('LineString') > -1 || response.features[0].geometry.type.indexOf('Polygon') > -1) {
              type = 'line';
              paint['line-color'] = geojson.color;
              paint['line-width'] = geojson.width ? geojson.width : 3;
            } else {
              paint['circle-color'] = geojson.color;
              paint['circle-radius'] = geojson.radius ? geojson.radius : 5;
            }
            var layout = { visibility : geojson.visible ? 'visible' : 'none'}
            map.addLayer({
              id : geojson.name,
              source : geojson.name,
              type : type,
              paint : paint,
              layout : layout
            }, 'gl-draw-line.cold')

            // Add invisble layer to help trigger popup
            var paintTouchable = {};
            if(type === 'line') {
              let lineWidth = geojson.touchableWidth ? geojson.touchableWidth : (geojson.width ? geojson.width * 5 : 8);
              paintTouchable['line-width'] = lineWidth;
              paintTouchable['line-opacity'] = 0;
            } else {
              paintTouchable['circle-color'] = 'rgba(0,0,0,0)';
              paintTouchable['circle-radius'] = geojson.radius ? geojson.radius : 5;
            }

            map.addLayer({
              id : `${geojson.name}-popup-trigger`,
              source : geojson.name,
              type : type,
              paint : paintTouchable,
              layout : layout
            })

            if (response.features[0].geometry.type === "Polygon") {
                map.addLayer({
                  id : `${geojson.name}-popup-trigger-poly`,
                  source : geojson.name,
                  type : 'fill',
                  layout,
                  paint : {
                    'fill-color': '#000',
                    'fill-opacity': 0
                  },
                }, 'gl-draw-line.cold')
            }

          }
      }

      map.on('touchstart', (e) => {
          if (showFeaturePopupsRef.current) {
            let features = map.queryRenderedFeatures(e.point);
            let topFeature = features.find(feature => feature.layer.id.includes('popup-trigger'));

            if (topFeature) { openPopup(e, topFeature); }
          }
      })
      map.on('click', (e) => {
         if (showFeaturePopupsRef.current) {
            let features = map.queryRenderedFeatures(e.point);
            let topFeature = features.find(feature => feature.layer.id.includes('popup-trigger'));
            //console.log(features.map(f => f.layer.id));
            //console.log(topFeature.layer.id, ' TOP FEATURE');

            if (topFeature) { openPopup(e, topFeature); }
         }
      })

      var popup = new mapboxgl.Popup({ closeOnClick: false })
      const openPopup = (e, feature) => {
          const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

          if (feature.properties.direct_link_url && !isSafari) {
            window.open(feature.properties.direct_link_url);
          } else {
              popup.setLngLat([e.lngLat.lng, e.lngLat.lat]);
              var html = '';
              if (feature.properties.direct_link_url && isSafari) {
                html = `<a href=${feature.properties.direct_link_url} target="_blank" class="safari-popup-link">Open Link</a>`;
              } else {
                  JSON.parse(feature.properties.propsToShow).forEach(prop => {
                    if(feature.properties[prop]) {
                      html += `<p><strong>${prop}</strong>: ${feature.properties[prop]}</p>`;
                    }
                  })
              }
              popup.setHTML(html);
              popup.addTo(map);
          }
      }


    }
  }

  const toggleGeoJSONLayer = index => {
    var newGeoJSONVisibility = JSON.parse(JSON.stringify(geoJSONVisibility))
    newGeoJSONVisibility[index].visible = !newGeoJSONVisibility[index].visible;
    setGeoJSONVisibility(newGeoJSONVisibility);
  }

  const setAnnotations = (map, features, geojson) => {

    //create format mapbox expression
    const format = ['format', '']

    const prop = '';
    const textLines = geojson.annotations.split("\n")

    //create mapbox text-field expression
    textLines.forEach(line =>{
      let newPropertyValue = ''
      let creatingPropValue = false;
      for (let char of line) {
        if(char === "{"){
          creatingPropValue = true;
        }
        else if(char === "}"){
          creatingPropValue = false;
        }
        if(creatingPropValue && char !== "{"){
          newPropertyValue += char;
        }
        else if (newPropertyValue.length > 0){
          format.push(["get", newPropertyValue])
          // format.push({ 'font-scale': 0.5 })
          format.push('')
          newPropertyValue = '';
        }
        else if(char !== "{"){
          format[format.length - 1] += char
        }
      }
      format.push('\n')
    })

    let featuresCopy = JSON.parse(JSON.stringify(features));

    const textData = {
      "type": "FeatureCollection",
      "features": []
    }

    featuresCopy.forEach((feature, i) => {

      if (feature.geometry.type === "Polygon") {
          // Get centre of Polygon
          feature.geometry = turf.centroid(feature).geometry;
      } else if (feature.geometry.type === "LineString") {
          // Get midpoint of LineString
          const startPoint = feature.geometry.coordinates[0];
          const endPoint = feature.geometry.coordinates[feature.geometry.coordinates.length - 1];
          const lineDist = turf.distance(turf.point(startPoint), turf.point(endPoint));

          feature.geometry = turf.along(feature, lineDist / 2).geometry;
      }

      textData.features.push({
        "type": "Feature",
        "properties": {...feature.properties},
        "geometry" : feature.geometry,
        }
      )

    }) // End loop

    if (!map.getSource(`feature-label-${geojson.name}`)) {
      map.addSource(`feature-label-${geojson.name}`, {
        type : 'geojson',
        data : textData
      });
    }
    map.addLayer({
        "id": `feature-label-layer-${geojson.name}`,
        "type": "symbol",
        "source": `feature-label-${geojson.name}`,
        "layout": {
            'text-size': geojson.fontSize,
            'text-field': format,
            // "text-font": [geojson.fontFamily, "Open Sans Regular","Arial Unicode MS Regular"],//doesnt work?
        },
        "paint" : {
            'text-color' : geojson.fontColor,
            'text-halo-color': geojson.fontShadow,
            'text-halo-width': 1,
            'text-halo-blur': 1
        }
    });
  }

  const loadTilesets = (map, tilesets) => {
    if(tilesets.length > 0) {
      setTilesetVisibility(tilesets)
      tilesets.forEach(tileset => {
        fetch(`https://api.mapbox.com/v4/${tileset.id}.json?access_token=${mapboxgl.accessToken}`).then(resp => resp.json()).then(response => {
          if(!map.getSource(tileset.id)) {
            map.addSource(tileset.id, {
              type: 'raster',
              tiles: [ `https://api.mapbox.com/v4/${tileset.id}/{z}/{x}/{y}.png` ]
            });
            map.addLayer({
              id : tileset.id,
              source : tileset.id,
              type : 'raster',
              layout : {
                'visibility' : 'none'
              }
            }, 'geojson-layerer')
            map.fitBounds(response.bounds, { duration : 0, padding: 20 });
            setCurrentLngLat(map.getCenter());
            if(tileset.visible) {
              map.setLayoutProperty(tileset.id, 'visibility', 'visible')
            }
          }
        });
      })
    }
  }

  useEffect(() => {
    if(map && tilesetVisibility.length > 0) {
      tilesetVisibility.forEach(tileset => {
        map.setLayoutProperty(tileset.id, 'visibility', tileset.visible ? 'visible' : 'none')
      })
    }
  }, [tilesetVisibility])

  useEffect(() => {
    if(map && geoJSONVisibility.length > 0) {
      let allLayers = map.getStyle().layers;

      geoJSONVisibility.forEach(geojson => {
        if (map.getLayer(geojson.name)) {
            map.setLayoutProperty(geojson.name, 'visibility', geojson.visible ? 'visible' : 'none')
            map.setLayoutProperty(geojson.name + '-popup-trigger', 'visibility', geojson.visible ? 'visible' : 'none')
        }

        // Feature labels
        const labelLayers = allLayers.filter(layer => layer.id.includes(`feature-label-layer-${geojson.name}`));
        labelLayers.forEach(layer => {
            map.setLayoutProperty(layer.id, 'visibility', geojson.visible ? 'visible' : 'none')
        })

        // Popup Triggers
        const popupLayers = allLayers.filter(layer => layer.id.includes(`${geojson.name}-popup`));
        popupLayers.forEach(layer => {
            map.setLayoutProperty(layer.id, 'visibility', geojson.visible ? 'visible' : 'none')
        })
      })
    }
  }, [geoJSONVisibility]);

  const getElevationDiff = elevations => {
    const startEl = elevations.find(x=>x!==undefined).properties.VALUE;
    const endEl = elevations[elevations.length - 1].properties.VALUE;

    return (endEl - startEl).toFixed(2);
  }

  // console.log(elevationsToDisplay[openElevation] ? elevationsToDisplay[openElevation].map(elev => elev.properties.VALUE).reduce((max, val) => max > val ? Math.ceil(max) : Math.ceil(val)) : 0)
  // console.log(elevationsToDisplay[openElevation] ? elevationsToDisplay[openElevation].map(elev => elev.properties.VALUE).reduce((min, val) => min < val ? Math.floor(min) : Math.floor(val)) : 0)
  // console.log(featuresToMeasure[openElevation] ? (turf.length(featuresToMeasure[openElevation])*100) : 0)
  // console.log(featuresToMeasure[openElevation] ? (Math.round(turf.length(featuresToMeasure[openElevation])*100)*10) : 0)

  return (
    <div>
      <div id="map" style={{height: window.innerHeight - 56}}></div>

      { map ? // Without check, component mounts twice
          <MarkupTool map={map} toggleMarkupTool={toggleMarkupTool} toolActive={markupToolActive} />
      : false }

      <div className="mapboxgl-ctrl-group" onClick={() => toggleMarkupTool(true)} style={{ position : 'absolute', top : 245, left : 10, fontFamily : 'serif' }}>
        <button>A</button>
      </div>

      <div className="mapbox-ctrl-group" style={{ position : 'absolute', bottom : 10, left : 10, fontSize: 12 }}>
        <div style={{background: 'white', padding: 5}}>
          <table>
            <tr>
              <td><strong>Lat</strong></td>
              <td>{currentLngLat ? currentLngLat.lat.toFixed(6) : false}</td>
              <td style={{paddingLeft:10}}>{currentLngLat ? proj4(findProjectionString(currentLngLat.lng), [currentLngLat.lng, currentLngLat.lat])[1].toFixed(2) : false}</td>
            </tr>
            <tr>
              <td><strong>Long</strong></td>
              <td>{currentLngLat ? currentLngLat.lng.toFixed(6) : false}</td>
              <td style={{paddingLeft:10}}>{currentLngLat ? proj4(findProjectionString(currentLngLat.lng), [currentLngLat.lng, currentLngLat.lat])[0].toFixed(2) : false}</td>
            </tr>
          </table>
        </div>
      </div>

      <div className="dropdown-picker">
        <DropdownButton title="Vectors" variant="light" size="sm" >
          <div onClick={(e) => e.stopPropagation()}>
            {geoJSONVisibility.map((geojson, index) => {
              return (
                <div className="dropdown-item" onClick={(e) => { toggleGeoJSONLayer(index) }}>
                  <Form.Check checked={geojson.visible} label={geojson.name} />
                </div>
              )
            })}
          </div>
        </DropdownButton>
        <DropdownButton title="Overlays" variant="light" size="sm" style={{marginTop: '10px'}}>
          <div onClick={(e) => e.stopPropagation()}>
            {tilesetVisibility.map((tileset, index) => {
              return (
                <div className="dropdown-item" onClick={(e) => {
                  var newTilesetVisibility = JSON.parse(JSON.stringify(tilesetVisibility))
                  newTilesetVisibility[index].visible = !newTilesetVisibility[index].visible;
                  setTilesetVisibility(newTilesetVisibility);
                }}>
                  <Form.Check checked={tileset.visible} label={tileset.name} />
                </div>
              )
            })}
          </div>
        </DropdownButton>

        <Button
            variant={showFeaturePopups ? "primary" : "light" }
            className="inquiry-tool"
            onClick={() => setShowFeaturePopups(!showFeaturePopups)}
        >
            <i className="fa fa-info-circle"></i>
        </Button>
        <p> </p>
      </div>

      { featuresToMeasure.length > 0 || measuredPolys.length > 0 ?
        <div className="distances">
          { featuresToMeasure.length > 0 && measuredPolys.length > 0 ?
              <ul className="nav nav-tabs">
                  <li className={"nav-item nav-link" + (polyOrLinePanel === 'lines' ? ' active' : '')} onClick={() => setPolyOrLinePanel('lines')}>Lines</li>
                  <li className={"nav-item nav-link" + (polyOrLinePanel === 'polys' ? ' active' : '')} onClick={() => setPolyOrLinePanel('polys')}>Polygons</li>
              </ul>
          : false }
          <div className="distances-body">
              {(!polyOrLinePanel || polyOrLinePanel === 'lines') &&  featuresToMeasure.map((line, index) => {
                return (
                  <p>
                    <strong>Line {index + 1}</strong> : { new Intl.NumberFormat().format((turf.length(line) * 1000).toFixed(2))} m
                    {elevationsToDisplay[index] ?
                        <span>
                            <span onClick={() => setOpenElevation(index)}>
                                <span> </span>
                                <u>See Profile:</u>
                            </span>
                            <span> {getElevationDiff(elevationsToDisplay[index])} m elevation change</span>
                        </span>
                    : false }
                  </p>
                )
              })}

              {(!polyOrLinePanel || polyOrLinePanel === 'polys') && measuredPolys.length > 0 && measuredPolys.map((poly, index) => {
                return (
                  <p>
                    <strong>Polygon {index + 1}</strong> : { new Intl.NumberFormat().format((turf.area(poly)).toFixed(2))} &#13217;
                  </p>
                )
              })}
          </div>
        </div>
      : false}

      <Modal size="lg" show={openElevation!==false} onHide={() => setOpenElevation(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Distance Measure</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{display: (openElevation!==false ? 'block' : 'none')}}>
            Line {openElevation + 1} <br />
            Distance : {featuresToMeasure[openElevation] ? new Intl.NumberFormat().format((turf.length(featuresToMeasure[openElevation]) * 1000).toFixed(2)) : 0} m
            <div>
              <ReactApexChart
                options={{
                  chart: {
                    type: 'line',
                    height: 350
                  },
                  dataLabels: {
                    enabled: false
                  },
                  stroke: {
                    curve : 'straight'
                  },
                  xaxis: {
                    type : 'numeric',
                    max: featuresToMeasure[openElevation] ? (Math.round(turf.length(featuresToMeasure[openElevation])*100)*10) : 0,
                    min: 0,
                    forceNiceScale: true,
                    // tickAmount : 5,
                    decimalsInFloat : 0,
                    title: {
                      text: 'Distance (metres)',
                      offsetY : 12
                    },
                    // labels: {
                    //     show: true,
                    //     formatter: function(val, timestamp) {
                    //         return val * 1000;
                    //     }
                    // }
                    // type : 'numeric',
                    // max: 20,
                    // min: 0,
                    // tickAmount : featuresToMeasure[openElevation] ? ((turf.length(featuresToMeasure[openElevation])*5)%5) : 0,
                    // decimalsInFloat : 0,
                    // title: {
                    //   text: 'Distance (metres)'
                    // },
                    // labels: {
                    //     show: true,
                    //     formatter: function(val, timestamp) {
                    //         return (Math.round((turf.length(featuresToMeasure[openElevation])*1000)/20) * val)
                    //     }
                    // }
                  },
                  yaxis: {
                    max : elevationsToDisplay[openElevation] ? elevationsToDisplay[openElevation].map(elev => elev.properties.VALUE).reduce((max, val) => max > val ? Math.ceil(max) : Math.ceil(val)) : 0,
                    min : elevationsToDisplay[openElevation] ? elevationsToDisplay[openElevation].map(elev => elev.properties.VALUE).reduce((min, val) => min < val ? Math.floor(min) : Math.floor(val)) : 0,
                    decimalsInFloat : 2,
                    // forceNiceScale: true,
                    title: {
                      text: 'Elevation (metres)'
                    }
                  },
                  fill: {
                    opacity: 1
                  },
                  tooltip: {
                    y: {
                      formatter: function (val) {
                        return val + " metres"
                      }
                    }
                  }
                }}
                series={[{
                  name: 'Elevation',
                  data: elevationsToDisplay[openElevation] ? elevationsToDisplay[openElevation].map(elev => { return { y : elev.properties.VALUE.toFixed(2), x : elev.properties.distance*1000 }}) : []
                }]}
                type="line"
                height={350}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setOpenElevation(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  elevationTileset : state.elevation.elevationTileset,
  geojsons: state.geojsons.allGeoJSONs,
  tilesets : state.tilesets.allTilesets,
  projection : state.projection.projectionString,
  pointclouds: state.pointclouds.allPointclouds,
  bbox : state.map.bbox,
  bboxData : state.map.bboxData
});

export default connect(mapStateToProps)(FlatMap);
