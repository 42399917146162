import { useEffect, useState } from 'react';
import { DropdownButton, Dropdown, Form } from 'react-bootstrap';
import proj4 from 'proj4';

import { connect } from "react-redux";

import { awsRootURL, folderName } from '../Constants';

const Potree = window.Potree;
const ProgressBar = window.ProgressBar;
const THREE = window.THREE;
var savedMeasuredLines = [];

function PointCloud({ pointclouds }) {
   const [ measuredLines, setMeasuredLines ] = useState(false);

  useEffect(() => {
    if(pointclouds.length > 0) {
      var visiblePointcloud = pointclouds.find(pointcloud => pointcloud.visible);
      renderPointcloud(visiblePointcloud)

    }
  }, [pointclouds]);

  window.returnProjectedPoints = (points, bounds3D, worldBounds) => {
    // Finding percentage of distance and translating to real-world measurements
    var measurementPoint = points[0];
    var endMeasurementPoint = points[points.length-1];
    var startPoint = new THREE.Vector3(0,measurementPoint.y,0);
    var endPoint = new THREE.Vector3(0,endMeasurementPoint.y,0);
    var distanceAndElevation = {
      distance : measurementPoint.distanceTo(endMeasurementPoint),
      elev : startPoint.distanceTo(endPoint)
    }
    savedMeasuredLines.push(distanceAndElevation);
    setMeasuredLines(distanceAndElevation);
  }

  // window.measurementsWatcher = {
  //   value: [],
  //   update() {
  //       console.log(measuredLines);
  //       setMeasuredLines([...this.val]);
  //   },
  //   get val() {
  //       return this.value;
  //   },
  //   set val(value) {
  //       this.value = value;
  //       this.update();
  //   }
  // }

  const renderPointcloud = (pointcloudToLoad) => {
    var pointcloudPath = awsRootURL + folderName + "/pointclouds/" + pointcloudToLoad.folder + "/cloud.js";

    document.getElementById('potree_render_area').innerHTML = '';

    fetch(pointcloudPath).then(resp => resp.json()).then(response => {
      window.originalBounds = response.tightBoundingBox;
    })

    window.runPotree({
			path:			pointcloudPath,
			cameraPosition: null,
			cameraTarget: null,
      showStats : false,
			sizeType: 		"Fixed",			// options: "Fixed", "Attenuated", "Adaptive"
			quality: 		"Squares", 			// options: "Squares", "Circles", "Interpolation", "Splats"
			fov: 			75, 				// field of view in degree
			material: 		"RGB", 				// options: "RGB", "Height", "Intensity", "Classification"
			pointLimit: 	1,					// max number of points in millions
			navigation: 	"Orbit",			// options: "Earth", "Orbit", "Flight"
			pointSize: 		2,
			useEDL:			false,				// eye-dome-lighting, especially usefull for point clouds without normals
		})
  }

  return (
    <div className="potree_container" >
      <div id="potree_render_area" style={{width:'100%', height: (window.innerHeight - 56)+'px'}}></div>
    	<div id="tools" className="mapboxgl-ctrl-group">
    		<button className="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_line" onClick={() => window.measuringTool.startInsertion({showDistances: true, showArea: false, closed: false})} title="distance measurements"/>
    		<button className="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon" onClick={() => window.measuringTool.startInsertion({showDistances: true, showArea: true, closed: true})} title="area measurements"/>
    	</div>

        { savedMeasuredLines.length > 0 ?
            <div className="distances" style={{ marginTop : 10, background : '#fff', padding : 8}}>
                {savedMeasuredLines.map((line, i) => {
                    return (
                        <div key={`line-${i}`}>
                            <span> {line.distance.toFixed(2)} m distance; </span>
                            <span> {line.elev.toFixed(2)} m elevation change</span>
                        </div>
                    )
                })}
            </div>
        : false }
      <div className="dropdown-picker">
        <DropdownButton title="Pointclouds" variant="light" size="sm" >
          {pointclouds.map(pointcloud => {
            return (
              <Dropdown.Item onClick={() => renderPointcloud(pointcloud)}>{pointcloud.name}</Dropdown.Item>
            )
          })}
        </DropdownButton>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  pointclouds: state.pointclouds.allPointclouds
});

export default connect(mapStateToProps)(PointCloud);
