import { useState, useEffect } from 'react';
import * as turf from '@turf/turf';
import proj4 from 'proj4';
import { DropdownButton, Dropdown, Form } from 'react-bootstrap';

import { connect } from "react-redux";

import { awsRootURL, folderName } from '../Constants';

const THREE = window.THREE_NEW;

function Terrain({ skins }) {

  const [ demInfo, setDemInfo ] = useState(false);
  const [ mainPlane, setMainPlane ] = useState(false);
  const [ currentScene, setCurrentScene ] = useState(false);
  const [ currentRenderer, setCurrentRenderer ] = useState(false);
  const [ currentGeometry, setCurrentGeometry ] = useState(false);
  const [ currentSkin, setCurrentSkin ] = useState(false);

  const reproject = (coords) => {
    return proj4('+proj=utm +zone=11 +ellps=GRS80 +datum=NAD83 +units=m +no_defs', 'WGS84', coords);
  }

  // Set up necessary info from render stuff
  useEffect(() => {
    if(skins.length > 0) {
      const demFile = awsRootURL + folderName + '/dem_file.bin?v=' + (Math.random() * 100);
      const demJsonFile = awsRootURL + folderName + '/dem_file.json?v=' + (Math.random() * 100);

      fetch(demJsonFile).then(resp => resp.json()).then(response => {
       // console.log(response);
       var demInfo = {
         file : demFile,
         size : response.size,
         center : response.cornerCoordinates.center,
         minMax : [ response.bands[0].computedMin, response.bands[0].computedMax ],
         bounds : response.cornerCoordinates,
         boundsMeasurement : [
           turf.distance(reproject(response.cornerCoordinates.upperLeft), reproject(response.cornerCoordinates.upperRight))*1000,
           turf.distance(reproject(response.cornerCoordinates.upperLeft), reproject(response.cornerCoordinates.lowerLeft))*1000
         ]
       }
       setDemInfo(demInfo);
      })
    }
  }, [skins]);

  // Load rendering
  useEffect(() => {
    if(demInfo && !mainPlane) {

      var width  = window.innerWidth,
          height = window.innerHeight - 65;

      var scene = new THREE.Scene();
      scene.background = new THREE.Color(0x333333)
      setCurrentScene(scene);

      var camera = new THREE.PerspectiveCamera(45, width / height, 0.1, 10000);
      camera.position.set(0, -50, 50);
      camera.updateMatrixWorld()

      var renderer = new THREE.WebGLRenderer();
      renderer.setSize(width, height);
      renderer.sortObjects = false
      setCurrentRenderer(renderer);

      var terrainLoader = new THREE.TerrainLoader();
      terrainLoader.load(demInfo.file + '?v=' + (Math.random() * 100), data => {

          var demWidth = demInfo.size[0];
          var demHeight = demInfo.size[1];
          var demHeightWidthRatio = demWidth/304;
          var heightForRender = demHeight/demHeightWidthRatio;

          var heightDifference = Math.ceil(demInfo.minMax[1]) - Math.floor(demInfo.minMax[0]);

          var scaledSizeX = demInfo.boundsMeasurement[0]/10;
          var scaledSizeY = demInfo.boundsMeasurement[1]/10;
          var baseScaleWidth = 600; // distance width with normal base size (0, -50, 50);
          var baseScaleHeight = 400; // distance height with normal base size (0, -50, 50);
          var thisScaleWidth = demInfo.boundsMeasurement[0]/baseScaleWidth;
          var thisScaleHeight = demInfo.boundsMeasurement[1]/baseScaleHeight;
          var thisScale = thisScaleWidth > thisScaleHeight ? thisScaleWidth : thisScaleHeight;

          var geometry = new THREE.PlaneGeometry(
            scaledSizeX, scaledSizeY,
            304-1, Math.ceil(heightForRender)-1);

          // Scale is 10
          for (var i = 0, l = geometry.vertices.length; i < l; i++) {
              geometry.vertices[i].z = (data[i] / 65535) * (heightDifference/10);
          }
          setCurrentGeometry(geometry);

          var currentVisibleSkin = skins.find(skin => skin.visible);
          setCurrentSkin(currentVisibleSkin);

          scene.add(new THREE.AmbientLight(0xeeeeee));

          camera.position.set(0, -50*thisScale, 50*thisScale);
      });

      var controls = new THREE.TrackballControls(camera);
      controls.minDistance = 5;

      render();

      function render() {
          controls.update();
          requestAnimationFrame(render);
          renderer.render(scene, camera);
      }

      document.getElementById('webgl2').appendChild(renderer.domElement);
      document.getElementById('webgl2').addEventListener( 'touchstart', (e) => e.preventDefault(), false );

    }
  }, [demInfo])

  useEffect(() => {

    if(currentRenderer && currentSkin && currentGeometry) {
      var texture = new THREE.TextureLoader().load(currentSkin.file + '?v=' + (Math.random() * 100));
      texture.anisotropy = currentRenderer.capabilities.getMaxAnisotropy();

      var material = new THREE.MeshStandardMaterial({
          transparent: true,
          map : texture,
          side: THREE.DoubleSide
      });
      material.map.minFilter = THREE.LinearFilter
      if(mainPlane) {
        mainPlane.material = material;
      } else {
        var plane = new THREE.Mesh(currentGeometry, material);
        currentScene.add(plane);
        setMainPlane(plane);
      }
    }

  }, [currentSkin])

  return (
    <div>
      <div id="webgl2" />
      <div className="dropdown-picker">
        <DropdownButton title="Overlays" variant="light" size="sm" >
          {skins.map(skin => {
            return (
              <Dropdown.Item onClick={() => setCurrentSkin(skin)}>{skin.name}</Dropdown.Item>
            )
          })}
        </DropdownButton>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  skins: state.skins.allSkins,
});

export default connect(mapStateToProps)(Terrain);
