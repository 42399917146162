export const SET_BOUNDS = 'SET_BOUNDS';
export const SET_BOUNDS_DATA = 'SET_BOUNDS_DATA';

export function getBoundsFromLatLngEntry(lat, lng) {
  return (dispatch) => {
    return fetch(`https://d1n9idd9wzsswi.cloudfront.net/api/bounds/section?lng=${lng}&lat=${lat}`).then(resp => resp.json()).then(response => {
      dispatch(setBoundsData({
        mer : response.mer,
        rge : response.rge,
        twp : response.twp,
        sec : response.sec
      }))
      dispatch(setBounds(response.geojson))
    })
    .catch(error => console.log(error));
  }
}

export function getBoundsFromEntry(mer, rge, twp, sec, qtr) {
  return (dispatch) => {
    var queryString = `mer=${mer}&rge=${rge}&twp=${twp}`
    if(sec !== '') {
      queryString += `&sec=${sec}`
    }
    if(qtr !== '') {
      queryString += `&qtr=${qtr}`
    }
    return fetch(`https://d1n9idd9wzsswi.cloudfront.net/api/bounds/search?${queryString}`).then(resp => resp.json()).then(response => {
      dispatch(setBounds(response.bbox))
    })
    .catch(error => console.log(error));
  }
}

export function setBoundsData(data) {
  return {
    type : SET_BOUNDS_DATA,
    payload : data
  }
}

export function setBounds(data) {
  return {
    type : SET_BOUNDS,
    payload : data
  }
}
