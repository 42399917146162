import { combineReducers } from 'redux';

import geojsons from './reducer_geojsons';
import skins from './reducer_skins';
import pointclouds from './reducer_pointclouds';
import tilesets from './reducer_tilesets';
import files from './reducer_files';
import elevation from './reducer_elevation';
import projection from './reducer_projection';
import map from './reducer_map';
//import measurements from './reducer_measurements';

const rootReducer = combineReducers({
  elevation,
  projection,
  geojsons,
  skins,
  pointclouds,
  tilesets,
  files,
  map
  //measurements
});

export default rootReducer;
