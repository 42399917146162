import { SET_INITIAL_DATA } from '../actions/actions_initial_data';

const elevation = (state = {
  elevationTileset : false
}, action) => {
  switch (action.type) {
    case SET_INITIAL_DATA:
      var elevationTileset = action.payload.elevation;
      return {
        ...state,
        elevationTileset : elevationTileset
      };
    default:
      return state
  }
}

export default elevation
