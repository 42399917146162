import { useState, useEffect } from 'react';
import { Modal, Button, Card, Accordion } from 'react-bootstrap'

import { connect } from "react-redux";

function Files({ files, modalShow, changeModalShow }) {

  const createFileLayout = (file) => {
    var fileLayout = [];
    if(file.folderName) {
      var thisFileSet = (
        <Accordion>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="light" eventKey={file.folderName}>
                &#9656; {file.folderName}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={file.folderName}>
              <div>
                {file.files.map(thisFile => createFileLayout(thisFile))}
              </div>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      )
      fileLayout.push(thisFileSet);
    } else {
      fileLayout.push(
        <Card>
          <Card.Body>
            <Card.Link target="_blank" href={file.file}>{file.name}</Card.Link>
          </Card.Body>
        </Card>
      )
    }
    return fileLayout
  }

  return (
    <div>
      <Modal show={modalShow} onHide={() => changeModalShow(!modalShow)}>
        <Modal.Header closeButton>
          <Modal.Title>File Browser</Modal.Title>
        </Modal.Header>
        <Modal.Body className="file-modal">
          {files && files.map(file => {
            return createFileLayout(file);
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => changeModalShow(!modalShow)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  files: state.files.allFiles
});

export default connect(mapStateToProps)(Files);
