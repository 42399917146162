import { awsRootURL, folderName } from '../Constants';

import { SET_INITIAL_DATA } from '../actions/actions_initial_data';

const geojsons = (state = {
  allGeoJSONs : []
}, action) => {
  switch (action.type) {
    case SET_INITIAL_DATA:
      var allGeoJSONs = [];
      if(action.payload.geojsons) {
        action.payload.geojsons.forEach(geojson => {
          var thisGeoJSON = JSON.parse(JSON.stringify(geojson));
          if(thisGeoJSON.file.indexOf('http') === -1) {
            thisGeoJSON.file = awsRootURL + folderName + '/geojsons/' + thisGeoJSON.file;
          }
          if(geojson.color) {
            if(geojson.color.indexOf('#') === -1) {
              thisGeoJSON.color = '#' + geojson.color;
            } else {
              thisGeoJSON.color = geojson.color;
            }
          } else if(geojson.icon) {
            thisGeoJSON.icon = './img/' + geojson.icon + '.png';
            thisGeoJSON.hoverIcon = './img/' + geojson.icon + '-hover.png';
          }
          allGeoJSONs.push(thisGeoJSON)
        })
      }
      return {
        ...state,
        allGeoJSONs : allGeoJSONs
      };
    default:
      return state
  }
}

export default geojsons
